export const LEVELS = {
  a1: 'a1',
  a2: 'a2',
  b1: 'b1',
  b2: 'b2',
  c1: 'c1',
  c2: 'c2',
} as const

export type Level = keyof typeof LEVELS

type Question = {}

type Test = {
  slug: string
  title: string
  cover: string
  level: Level
  questions: Array<Question>
}

type Tests = Record<Level, Array<Test>>

export const tests = {
  a1: [
    {
      slug: 'genere-e-numero',
      title: 'Genere e numero',
      level: 'a1',
      cover:
        'https://images.unsplash.com/photo-1611348586804-61bf6c080437?w=300&dpr=2&q=80',
      questions: [],
    },
    {
      slug: 'le-parti-del-corpo',
      title: 'Le parti del corpo',
      level: 'a1',
      cover:
        'https://images.unsplash.com/photo-1468817814611-b7edf94b5d60?w=300&dpr=2&q=80',
      questions: [],
    },
    {
      slug: 'topic-3',
      title: 'Topic 3',
      level: 'a1',
      cover:
        'https://images.unsplash.com/photo-1528143358888-6d3c7f67bd5d?w=300&dpr=2&q=80',
      questions: [],
    },
    {
      slug: 'topic-4',
      title: 'Topic 4',
      level: 'a1',
      cover:
        'https://images.unsplash.com/photo-1490300472339-79e4adc6be4a?w=300&dpr=2&q=80',
      questions: [],
    },
    {
      slug: 'topic-4',
      title: 'Topic 4',
      level: 'a1',
      cover:
        'https://images.unsplash.com/photo-1490300472339-79e4adc6be4a?w=300&dpr=2&q=80',
      questions: [],
    },
    {
      slug: 'topic-4',
      title: 'Topic 4',
      level: 'a1',
      cover:
        'https://images.unsplash.com/photo-1490300472339-79e4adc6be4a?w=300&dpr=2&q=80',
      questions: [],
    },
  ],
  a2: [],
  b1: [],
  b2: [],
  c1: [],
  c2: [],
} as const satisfies Tests
